import React from "react";
import styled from "styled-components";
import { useState, useEffect } from "react";
import LogoQuest from "@images/Logos/Logo6.png";
import { Squash as Hamburger } from 'hamburger-react';
import Modal from "../Modal/Modal";
import { Link } from "gatsby";
import { FaCaretDown } from "@react-icons/all-files/fa/FaCaretDown";
import Container from "@components/Container/Container";

const Navbar = () => {


    const [isOpen, setOpen] = useState(false);



    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 768) {
                setOpen(() => false);
            } else { };
        };
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);



    return (
        <Wrapper isOpen={isOpen}>
            <Container bg={"#26578f"} znumber={39} className={"flex flex-col justify-center items-center"}>
                <nav className={"w-full mt-0 mb-0 ml-auto mr-auto py-2 flex flex-row items-center justify-between bg-tema4 font-semibold z-40"}>
                    <div>
                        <Link to={"/"}>
                            <img className={"w-60 md:w-60 mx-1 md:mx-8"} src={LogoQuest} alt={LogoQuest}>
                            </img>
                        </Link>
                    </div>
                    <div className={"navbar justify-end items-center hidden sm:hidden md:hidden lg:flex xl:flex z-40 mr-5 text-white"}>
                        <ul className={"flex flex-row"}>
                            <li className={"navbarlevel1 mx-2 py-2 px-1 my-3"}>
                                Acerca de <FaCaretDown className={"inline-block"} />
                                <ul className={"absolute flex-col navbarlevel2 hidden"}>
                                    <li className="z-40 px-4 py-2 border-2 border-gray-200 bg-tema4">
                                        <Link to={"/acerca/que-es"}>
                                            ¿Qué es QuEST?
                                        </Link>
                                    </li>
                                    <li className="z-40 px-4 py-2 border-2 border-gray-200 bg-tema4">
                                        <Link to={"/acerca/objetivos"}>
                                            Objetivos
                                        </Link>
                                    </li>
                                    <li className="z-40 px-4 py-2 border-2 border-gray-200 bg-tema4">
                                        <Link to={"/acerca/estructura"}>
                                            Estructura
                                        </Link>
                                    </li>
                                    <li className="z-40 px-4 py-2 border-2 border-gray-200 bg-tema4">
                                        <Link to={"/acerca/financiamiento"}>
                                            Financiamiento
                                        </Link>
                                    </li>
                                    <li className="z-40 px-4 py-2 border-2 border-gray-200 bg-tema4">
                                        <Link to={"/acerca/partners"}>
                                            Aliados
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                            <li className={"navbarlevel1 mx-2 py-2 px-1 my-3"}>
                                Calidad en salud <FaCaretDown className={"inline-block"} />
                                <ul className={"absolute flex-col navbarlevel2 hidden"}>
                                    <li className="z-40 px-4 py-2 border-2 border-gray-200 bg-tema4">
                                        <Link to={"/calidadensalud/definiciones"}>
                                            Definiciones
                                        </Link>
                                    </li>
                                    <li className="z-40 px-4 py-2 border-2 border-gray-200 bg-tema4">
                                        <Link to={"/calidadensalud/importancia"}>
                                            Importancia en LAC
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                            <li className={"navbarlevel1 mx-2 py-2 px-1 my-3"}>
                                Proyectos <FaCaretDown className={"inline-block"} />
                                <ul className={"absolute flex-col navbarlevel2 hidden"}>
                                    <li className="z-40 px-4 py-2 border-2 border-gray-200 bg-tema4">
                                        <Link to={"/proyectos/enmarcha"}>
                                            En Marcha
                                        </Link>
                                    </li>
                                    <li className="z-40 px-4 py-2 border-2 border-gray-200 bg-tema4">
                                        <Link to={"/proyectos/futuros"}>
                                            Futuros
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                            <li className={"navbarlevel1 mx-2 py-2 px-1 my-3"}>
                                Actividades <FaCaretDown className={"inline-block"} />
                                <ul className={"absolute flex-col navbarlevel2 hidden"}>
                                    <li className="z-40 px-4 py-2 border-2 border-gray-200 bg-tema4">
                                        <Link to={"/actividades/webinars"}>
                                            Webinars
                                        </Link>
                                    </li>
                                    <li className="z-40 px-4 py-2 border-2 border-gray-200 bg-tema4">
                                        <Link to={"/actividades/cursos-cortos"}>
                                            Cursos Cortos
                                        </Link>
                                    </li>
                                    <li className="z-40 px-4 py-2 border-2 border-gray-200 bg-tema4">
                                        <Link to={"/actividades/publicaciones"}>
                                            Publicaciones
                                        </Link>
                                    </li>
                                    <li className="z-40 px-4 py-2 border-2 border-gray-200 bg-tema4">
                                        <Link to={"/actividades/convocatorias"}>
                                            Convocatorias
                                        </Link>
                                    </li>

                                </ul>
                            </li>
                            <li className={"navbarlevel1 mx-2 py-2 px-1 my-3"}>
                                <Link to={"/contacto"}>Contacto </Link>
                            </li>
                            <li className={"navbarlevel1 mx-2 py-2 px-1 my-3"}>
                                <Link to={"http://questlacnet.xyz/"}>English </Link>
                            </li>
                        </ul>
                    </div>
                    <div className={"flex md:flex lg:hidden xl:hidden md:mx-8 mx-1"}>
                        <Hamburger toggled={isOpen} toggle={setOpen} color={"white"} />
                    </div>
                </nav>
                <Modal isOpen={isOpen} />
            </Container>
        </Wrapper>
    );




};

export default Navbar;

const Wrapper = styled.div`
border-bottom: 1px solid white;
display: flex;
flex-direction: column;
justify-content: center;
align-items:center;
position:relative;
width:100%;
background-color:var(--tema4);
z-index:39;
height: ${props=>props.isOpen?"auto":"85px"}
`;