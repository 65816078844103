import React from "react";
import { Link } from "gatsby";
import { BsFillHouseDoorFill } from "react-icons/bs";
import { BiWorld, BiPhoneCall } from "react-icons/bi";
import Container from "@components/Container/Container";
import upchlogo from "@images/upch.png";
import iecslogo from "@images/iecs.png";
import { FaMailBulk } from "react-icons/fa";
import {
  AiFillLinkedin,
  AiFillFacebook,
  AiFillTwitterSquare,
  AiFillYoutube,
} from "react-icons/ai";

const Footer = () => {
  let WrapperStyles =
    "flex justify-around items-center content-center bg-color4";
  WrapperStyles += " py-5  px-16 flex-col flex-nowrap text-xs";
  WrapperStyles += "  md:flex-row md:flex-wrap";
  WrapperStyles += "  lg:flex-row lg:flex-wrap";
  WrapperStyles += "  xl:flex-row xl:flex-wrap";
  WrapperStyles += " 2xl:flex-row 2xl:flex-wrap";

  return (
    <div className={"bg-color4 text-white"}>
      <Container>
        <div className={WrapperStyles} id={"ContactFooter"}>
          <div
            className={
              "py-3 flex flex-col items-center content-center justify-center xl:items-start lg:items-start w-60 text-center"
            }
          >
            <img className={"h-12 mx-auto"} src={upchlogo} alt={upchlogo} />
            <h1 className={"text-base lg:text-xl"}>
              Universidad Peruana Cayetano Heredia
            </h1>
            <span>
              <BsFillHouseDoorFill className={"inline mr-1"} />
              Av. Honorio Delgado 430, Urb Ingeniería
            </span>
            <span>
              <BiWorld className={"inline mr-1"} />
              Lima , Perú
            </span>
            <span>
              <BiPhoneCall className={"inline mr-1"} />
              (+51 1) 319-0000
            </span>
          </div>
          <div
            className={
              "py-3 flex flex-col items-center content-center justify-center xl:items-start lg:items-start w-60 text-center"
            }
          >
            <img className={"h-12 mx-auto"} src={iecslogo} alt={iecslogo} />
            <h1 className={"text-base lg:text-xl"}>
              Instituto de Efectividad Clínica y Sanitaria
            </h1>
            <span>
              <BsFillHouseDoorFill className={"inline mr-1"} />
              Dr. Emilio Ravignani 2024 (C1414CPV)
            </span>
            <span>
              <BiWorld className={"inline mr-1"} />
              Buenos Aires, Argentina
            </span>
            <span>
              <BiPhoneCall className={"inline mr-1"} />
              (+54 11) 4777-8767
            </span>
          </div>
          <div
            className={
              "py-3 flex flex-col items-center content-center justify-start xl:items-start lg:items-start"
            }
          >
            <div
              className={
                "flex flex-col items-center justify-center content-around font-2xl"
              }
            >
              <Link
                to={"/contacto"}
                className={
                  "flex flex-row items-center h-16 w-30 hover:text-tema4"
                }
              >
                <FaMailBulk className={"h-8 w-8 mx-2"} />
                <p className={"text-base cursor-pointer "}> Contáctanos</p>
              </Link>
              <div className="flex flex-col items-start content-center justify-start">
                <p className="w-full text-lg text-center">Síguenos</p>

                <a
                  href={"https://www.linkedin.com/company/quest-lac-network"}
                  target={"_blank"}
                  rel={"noreferrer"}
                  className={
                    "flex flex-row items-center h-8 text-md w-30 hover:text-tema4"
                  }
                >
                  <AiFillLinkedin className={"h-8 w-8 mx-2"} />
                  <p className={"text-base cursor-pointer "}>
                    QuEST LAC Network
                  </p>
                </a>
                <a
                  href={"https://twitter.com/QuestLAC"}
                  target={"_blank"}
                  rel={"noreferrer"}
                  className={
                    "flex flex-row items-center h-8 text-md w-30 hover:text-tema4"
                  }
                >
                  <AiFillTwitterSquare className={"h-8 w-8 mx-2"} />
                  <p className={"text-base cursor-pointer "}> Twitter</p>
                </a>
                <a
                  href={"https://lnkd.in/dTXaJ3-X"}
                  target={"_blank"}
                  rel={"noreferrer"}
                  className={
                    "flex flex-row items-center h-8 text-md w-30 hover:text-tema4"
                  }
                >
                  <AiFillFacebook className={"h-8 w-8 mx-2"} />
                  <p className={"text-base cursor-pointer "}> Facebook</p>
                </a>
                <a
                  href={"https://www.youtube.com/channel/UCd_CFji_Sj8fd6eeO2BkaeA"}
                  target={"_blank"}
                  rel={"noreferrer"}
                  className={
                    "flex flex-row items-center h-8 text-md w-30 hover:text-tema4"
                  }
                >
                  <AiFillYoutube className={"h-8 w-8 mx-2"} />
                  <p className={"text-base cursor-pointer "}> Youtube</p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Footer;
