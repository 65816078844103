import { createGlobalStyle } from "styled-components";
export const GlobalStyle = createGlobalStyle`



:root{
    --maxWidth: 1280px;
    --black: #000000;
    --white: #fff;
    --lightGrey: #eee;
    --medGrey: #353535;
    --transparent: #FFFFFF00;
    --transparentGrey: #E8E8E89E;
    --darkGrey: #1c1c1c;
    --fontArchiBig:3.5rem;
    --fontMegaBig:3rem;
    --fontSuperBig: 2.5rem;
    --fontMedBig: 2rem;
    --fontBig: 1.5rem;
    --fontMed: 1.2rem;
    --fontSmall: 1rem;
    --fontXSmall: 0.8rem;
    --color1: #1c508c;
    --color2: #d99549;
    --color3: #5899b9;
    --color4: #a6a6a6;
    --color5: #9bb8c9;
    --colorA: #a2fff9;
    --colorB: #65bcc3;
    --colorC: #5b7771;
    --tema4: #26578f;
    --resPhoneV: 320px;
    --resPhoneH: 481px;
    --resTabletH: 641px;
    --resTabletV: 961px;
    --resDesktopN: 1025px;
    --resDesktopB: 1281px;
    --BPWidth50: 210px;
    --BPWidth: 420px;
    --BPWidth2: 840px;

* {
    box-sizing:border-box;
    font-family: 'Georama', sans-serif;
    text-decoration: none;


    #___gatsby {
        margin:0;
        padding:0;
        top:0;
        left:0;
        position: absolute;
        width:100%;
        height:100%;
    }
}
body{
        margin:0;
        padding:0;
    }

    .navbarlevel1:hover {
                    .navbarlevel2{
                        display: flex;
                    }

                }

.link {
    cursor: pointer;
}


.center-row{
    display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	align-content: center;
}

.center-col{
    display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	align-content: center;
}

.around-col{
    display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	justify-content: space-around;
	align-items: center;
	align-content: center;
}

.around-row{
    display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-around;
	align-items: center;
	align-content: center;
}

.center-col-l{
    display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: stretch;
	align-content: center;
}

.center-row-l{
    display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: stretch;
	align-content: center;
}






}`;
