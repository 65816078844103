import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
height:100%;
width:100%;
display: flex;
flex-direction: column;
justify-items: center;
align-items:center;
align-content:center;
background-color: ${props=>props.bg?props.bg:""};
z-index: ${props=>props.znumber?props.znumber:""};
`

const Content = styled.div`
position:relative;
    height: 100%;
    width: 100%;
    display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: stretch;
        align-content: stretch;
`

const Container = ({children,bg="",znumber=""}) => {
    return (<Wrapper bg={bg} znumber={znumber}>
        <Content>
            {children}
        </Content>
    </Wrapper>)
}

export default Container;